import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import _ from 'lodash'

import common from '@/utils/common.js'
// import './styles/font/font_3348124_jgvyh5t9q7/iconfont.css';

import 'element-plus/dist/index.css'

// VITE_APP_TITLE

import App from './App.vue'
import router from './router/index'
import Directives from './utils/directive'
import setWindowPublic from './utils/h5-public.js' //通信桥
// import './styles/index.scss'
// import browserClient from '@apmplus/web'

// import Vconsole from 'vconsole' //h5调试

// const env = import.meta.env

// env.VITE_APP_TITLE == 'test' ? new Vconsole() : ''

// console.log(env.VITE_APP_TITLE)

setWindowPublic()
window._ = _
window.common = common

const app = createApp(App)
app.use(ElementPlus, {
  locale: zhCn,
})
app.use(Directives)
app.use(router)
app.mount('#app')


// console.log(import.meta.env.VITE_APP_TITLE)
// if(import.meta.env.VITE_APP_TITLE === 'prod'){
  // browserClient('init', {
  //   aid: 561826,
  //   token: 'c9e8a518ac1a4de383513f33eb4b0888',
  //   env: import.meta.env.VITE_APP_TITLE,
  //   release: '3e3e-third-web',
  //   plugins: {
  //     pageview: {
  //       routeMode: 'history',
  //     },
  //   },
  // })
  // 开启上报
  // browserClient('start')
// }

