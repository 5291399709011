<template>
  <div class="sidebar-wrapper">
    <Logo />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="route.path"
        :router="true"
        class="vertical-sidebar-menu-item"
      >
        <el-menu-item index="/batch">
          <span
            :class="{ active: route.path == '/batch' }"
            class="menu-icon icon-batch"
          ></span>
          <span>批量铺货</span>
        </el-menu-item>
        <el-menu-item index="/record">
          <span
            :class="{ active: route.path == '/record' }"
            class="menu-icon icon-record"
          ></span>
          <span>铺货记录</span>
        </el-menu-item>
        <el-menu-item index="/batchEdit">
          <span
            :class="{ active: route.path == '/batchEdit' }"
            class="menu-icon icon-edit"
          ></span>
          <span>批量修改</span>
        </el-menu-item>
        <el-menu-item index="/editRecord">
          <span
            :class="{ active: route.path == '/editRecord' }"
            class="menu-icon icon-edit-record"
          ></span>
          <span>修改记录</span>
        </el-menu-item>
        <el-menu-item index="/set">
          <span
            :class="{ active: route.path == '/set' }"
            class="menu-icon icon-set"
          ></span>
          <span>铺货设置</span>
        </el-menu-item>
        <el-menu-item index="/store">
          <span
            :class="{ active: route.path == '/store' }"
            class="menu-icon icon-store"
          ></span>
          <span>我的店铺</span>
        </el-menu-item>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script setup>
import Logo from './logo.vue'
import { useRoute } from 'vue-router'
import { toRaw } from 'vue'
const route = useRoute()
</script>

<style lang="scss" scoped>
.el-menu-item {
  span {
    color: #a4aacd;
  }
  &.is-active {
    span {
      color: #fff !important;
    }
  }
}
.sidebar-wrapper {
  font-family: MicrosoftYaHei;
  width: $sideBarWidth !important;
  background: $menuBg;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  overflow: hidden;
  box-shadow: 0 0 1px #888;
  color: #fff;
  .vertical-sidebar-menu-item {
    color: #fff;
    background: #464e75;
    border-right: 0;
    .el-menu-item {
      color: #fff;
      font-size: 14px;
      &.is-active {
        background: linear-gradient(90deg, #4464d3, #5377f3);
        &:hover {
          background: linear-gradient(90deg, #4464d3, #5377f3);
        }
      }
      &:hover {
        background: #515a83;
      }
    }
  }

  .menu-icon {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 13px;
  }
  .icon-batch {
    background: url('@/assets/imgs/icons/icon_goods_02.png') no-repeat
      center;
    &.active {
      background: url('@/assets/imgs/icons/icon_goods_01.png')
        no-repeat center;
    }
  }
  .icon-record {
    background: url('@/assets/imgs/icons/icon_record_02.png')
      no-repeat center;
    &.active {
      background: url('@/assets/imgs/icons/icon_record_01.png')
        no-repeat center;
    }
  }
  .icon-edit {
    background: url('@/assets/imgs/icons/icon_edit_gray.png')
      no-repeat center;
    &.active {
      background: url('@/assets/imgs/icons/icon_edit.png')
        no-repeat center;
    }
  }
  .icon-edit-record {
    background: url('@/assets/imgs/icons/icon_edit_record_gray.png')
      no-repeat center;
    &.active {
      background: url('@/assets/imgs/icons/icon_edit_record.png')
        no-repeat center;
    }
  }
  .icon-set {
    background: url('@/assets/imgs/icons/icon_set_02.png') no-repeat
      center;
    &.active {
      background: url('@/assets/imgs/icons/icon_set_01.png') no-repeat
        center;
    }
  }
  .icon-store {
    background: url('@/assets/imgs/icons/icon_store_02.png') no-repeat
      center;
    &.active {
      background: url('@/assets/imgs/icons/icon_store_01.png')
        no-repeat center;
    }
  }
}
</style>
