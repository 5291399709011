import Axios from 'axios'

import cookie from 'js-cookie'
import { ElMessage } from 'element-plus'

const baseApi = import.meta.env.VITE_APP_BASE_API
const baseURL = ''
let loginCallback = null
let prevCallback = null
if (topLevelDomain === '3e3e.cn') {
  loginCallback = import.meta.env.VITE_APP_LOGIN_CALLBACK_URL_3E3E
  prevCallback = import.meta.env.VITE_APP_LOGIN_PREV_URL_3E3E
} else if (topLevelDomain === 'go2.cn') {
  loginCallback = import.meta.env.VITE_APP_LOGIN_CALLBACK_URL_GO2
  prevCallback = import.meta.env.VITE_APP_LOGIN_PREV_URL_GO2
}else if (topLevelDomain === 'e3hui.com') {
  loginCallback = import.meta.env.VITE_APP_LOGIN_CALLBACK_URL_E3HUIM
  prevCallback = import.meta.env.VITE_APP_LOGIN_PREV_URL_E3HUIM
}else if (topLevelDomain === 'e3hui.cn') {
  loginCallback = import.meta.env.VITE_APP_LOGIN_CALLBACK_URL_E3HUIW
  prevCallback = import.meta.env.VITE_APP_LOGIN_PREV_URL_E3HUIW
}

// alert(loginCallback)
const axios = Axios.create({
  baseURL: baseApi + baseURL,
  timeout: 100000, //请求超时10s
})

// 前置拦截器--
axios.interceptors.request.use(
  (config) => {
    let user_hash = cookie.get('user_hash')
    let user_user_id = cookie.get('user_user_id')
    if (isNotEmpty(user_hash)) {
      config.headers['token'] = user_hash
    }
    if (isNotEmpty(user_user_id)) {
      config.headers['userId'] = user_user_id
    }

    if (topLevelDomain === '3e3e.cn') {
      config.headers['source'] = 1
    } else if (topLevelDomain === 'go2.cn') {
      config.headers['source'] = 2
    } else if (topLevelDomain === 'e3hui.com') {
      config.headers['source'] = 3
    } else if (topLevelDomain === 'e3hui.cn') {
      config.headers['source'] = 4
    }
    // console.log(config.headers)
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => {
    if (response.data.code !== 0) {
      if (response.data.code === 10001) {
        let callbackUrl = window.btoa(JSON.stringify({ callbackUrl: loginCallback }))
        window.location.href = prevCallback + callbackUrl
      }
      ElMessage.error(response.data.msg)
    }
    return response.data
  },
  (error) => {
    if (error.response && error.response.data) {
      const code = error.response.data.code
      const msg = error.response.data.data.msg
      ElMessage.error(`Code:${code},Message: ${msg}`)
      console.error(`[Axios Error]`, error.msg)
    } else {
      ElMessage.error(`${error}`)
    }
    return Promise.reject(error)
  }
)

export default axios
