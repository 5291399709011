import CryptoJS from 'crypto-js'
/**
 * 判断对象是否为空(可以判断所有类型)    true-->空  false-->非空
 console.log(Object.prototype.toString.call(1))  -->[object Number]
 console.log(Object.prototype.toString.call('1'))  -->[object String]
 console.log(Object.prototype.toString.call(undefined))  -->[object Undefined]
 console.log(Object.prototype.toString.call(null))  -->[object Null]
 console.log(Object.prototype.toString.call(true))  -->[object Boolean]
 console.log(Object.prototype.toString.call({}))  -->[object Object]
 console.log(Object.prototype.toString.call([]))  -->[object Array]
 console.log(Object.prototype.toString.call(function () {}))  -->[object Function]
 console.log(Object.prototype.toString.call(new Date()))  -->[object Date]
 console.log(Object.prototype.toString.call(/\d/))  -->[object RegExp]
 console.log(Object.prototype.toString.call(1n))  -->[object BigInt]
 console.log(Object.prototype.toString.call(Symbol()))  -->[object Symbol]
 * @param obj
 * @returns {boolean}
 */
export function isEmpty(obj) {
  // console.log(obj)
  let type = Object.prototype.toString.call(obj)
  if (
    type === '[object Number]' || //数组
    type === '[object Boolean]' || //Boolean类型
    type === '[object Function]' || //方法
    type === '[object Date]' || //时间
    type === '[object RegExp]' || //正则
    type === '[object BigInt]' || //特殊的数字类型
    type === '[object Symbol]' //Symbol类型（全局唯一值）
  ) {
    return false
  } else if (
    type === '[object Undefined]' ||
    type === '[object Null]'
  ) {
    return true
  } else if (type === '[object String]') {
    return _.trim(obj) === ''
  } else if (type === '[object Object]') {
    return JSON.stringify(obj) === '{}'
  } else if (type === '[object Array]') {
    return obj.length === 0
  }
}
window.isEmpty = isEmpty

export function isNotEmpty(obj) {
  return !isEmpty(obj)
}
window.isNotEmpty = isNotEmpty

/** input验证
obj={
  val: '',
  regs: [/^([1-9]\d{0,2}|1000)(\.\d{1})?$/ ], //验证1-1000(小数点1位)
  err: false,
  errText: '请输入1-1000(小数点1位)数字',
}
*/
export async function verify(obj) {
  if (obj.regs && obj.regs.length > 0) {
    for (let i = 0; i < obj.regs.length; i++) {
      let reg = obj.regs[i]
      // console.log(reg, '-->', obj.val, !reg.test(obj.val))
      if (!reg.test(obj.val)) {
        return true
      }
    }
  }
  return false
}

/**
 * 获取URL params 参数值
 * @param {string} name 参数名
 * @returns
 */
export const getUrlParmas = (name) => {
  let query = location.search
  let searchParams = new URLSearchParams(query)
  return searchParams.get(name)
}
/**
 * 添加URL params 参数值
 * 注意：只能单url
 */
export const setUrlParmas = (url, data) => {
  if (!url) return 'javascript:;'
  for (let key in data) {
    if (url.indexOf('?') > -1) {
      url += `&${key}=${data[key]}`
    } else {
      url += `?${key}=${data[key]}`
    }
  }
  // console.log('-return->', url)
  return url
}
/**
 * 格式化时间
 * @param {Date} date
 * @returns 2020-03-22 12:23:22
 */
export function formatDateTime(date) {
  var y = date.getFullYear()
  var m = date.getMonth() + 1 //注意这个“+1”
  m = m < 10 ? '0' + m : m
  var d = date.getDate()
  d = d < 10 ? '0' + d : d
  var h = date.getHours()
  h = h < 10 ? '0' + h : h
  var minute = date.getMinutes()
  minute = minute < 10 ? '0' + minute : minute
  var second = date.getSeconds()
  second = second < 10 ? '0' + second : second
  return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second
}
/**
 *  获取近几天时间范围数组
 *  @param day: 天数（比如最近3天则传 3 ）
 *  @returns 数组[starttime, endtime]
 *  */
export function getRecentTimeRange(day) {
  const end = new Date()
  const start = new Date()
  start.setTime(start.getTime() - 3600 * 1000 * 24 * day)
  return [formatDateTime(start), formatDateTime(end)]
}

/**
 * 删除对象中值为空的键，用于提交数据
 * @param {*} obj
 */
export function deleteObjNullKey(obj) {
  if (!obj) return
  for (let key in obj) {
    if (obj[key] === '' || obj[key] == null) {
      delete obj[key]
    }
  }
}
// aes加密
export function aesEncrypt(
  encryptString,
  key = '2016.01_go2plus_chengdu_china'
) {
  var arr = []
  var index = 0
  for (var i = 0; i < 16; i++) {
    arr.push(key.substr(index, 1))
    index = (index + 7) % key.length
  }
  let keynew = CryptoJS.enc.Utf8.parse(arr.join(''))

  var encrypted = CryptoJS.AES.encrypt(encryptString, keynew, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  })
  return encrypted.ciphertext.toString()
}

// 判断是哪个平台
export function getSourceStr() {
  let source = ""
  if (topLevelDomain === '3e3e.cn') {
    source = "生意网"
  } else if (topLevelDomain === 'go2.cn') {
    source = "购途网"
  } else if (topLevelDomain === 'e3hui.com') {
    source = "衣衫汇"
  } else if (topLevelDomain === 'e3hui.cn') {
    source = "衣衫汇"
  }
  return source
}

export default {
  isEmpty,
  isNotEmpty,
  verify,
  getUrlParmas,
  deleteObjNullKey,
  getSourceStr
}
