import request from '@/utils/request.js'

// 登录
export function check(data) {
  return request({
    url: '/api/user/check',
    method: 'POST',
    data,
  })
}

// 退出登录
export function logout(data) {
  return request({
    url: '/api/user/logout',
    method: 'POST',
    data,
  })
}