
// select懒加载
const lazySelect = {
  onMounted(el, {value}) {
    console.log(value);
  },
  beforeUpdate(el, binding){
    console.dir(el);
    const SELECTWRAP_DOM = document.querySelector(".el-select-dropdown .el-select-dropdown__wrap");
    // el-select 必须有类名 lazy-load-more-select  才会生效
    if(el.className.indexOf('lazy-load-more-select') != -1) {
      // console.log("当前选中得是懒加载select")
      if(SELECTWRAP_DOM){
        SELECTWRAP_DOM.addEventListener("scroll", function () {
          const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) binding.value();
        });
      }
    } 
  }
}

// 输入框范围限制
const limit = {
  onMounted(el, {value}) {
    console.log(value);
  },
  beforeUpdate(el, binding){
    console.dir(el);
    console.log(binding.value)
    if(binding.value > 100000 || binding.value < 0){
      console.log(el.className) 
    }
  },
}


const directivesList = {
  install: function (app) {
    app.directive("lazySelect",lazySelect)
    app.directive("limit",limit)
  }
}

export default directivesList


