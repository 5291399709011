<template>
    <div class="layout-header-wrapper">
        <div>
            <div class="htips" v-if="route.path === '/store'">小贴士：每个店铺均可享受免费试用1个月的福利哦~</div>
        </div>
        <div class="user-box">
            <div class="user">欢迎访问,<span>{{ cookie.get('user_username') }}</span></div>
            <el-button @click="logoutHandle" type="text">退出登录</el-button>
        </div>
    </div>
</template>

<script setup>
import cookie from 'js-cookie'
import { logout } from '@/api/user'
import { useRoute } from 'vue-router';
const route = useRoute()

let loginCallback = null
let prevCallback = null
if (topLevelDomain === '3e3e.cn') {
    loginCallback = import.meta.env.VITE_APP_LOGIN_CALLBACK_URL_3E3E
    prevCallback = import.meta.env.VITE_APP_LOGIN_PREV_URL_3E3E
} else if (topLevelDomain === 'go2.cn') {
    loginCallback = import.meta.env.VITE_APP_LOGIN_CALLBACK_URL_GO2
    prevCallback = import.meta.env.VITE_APP_LOGIN_PREV_URL_GO2
} else if (topLevelDomain === 'e3hui.com') {
    loginCallback = import.meta.env.VITE_APP_LOGIN_CALLBACK_URL_E3HUIM
    prevCallback = import.meta.env.VITE_APP_LOGIN_PREV_URL_E3HUIM
} else if (topLevelDomain === 'e3hui.cn') {
    loginCallback = import.meta.env.VITE_APP_LOGIN_CALLBACK_URL_E3HUIW
    prevCallback = import.meta.env.VITE_APP_LOGIN_PREV_URL_E3HUIW
}
async function logoutHandle() {
    const { code } = await logout()
    if (code == 0) {
        console.log(loginCallback)
        // alert(loginCallback)
        let callbackUrl = window.btoa(JSON.stringify({ 'callbackUrl': loginCallback }))
        window.location.href = prevCallback + callbackUrl
    }
    console.log(res)
}
</script>

<style lang="scss" scoped>
.layout-header-wrapper {
    height: $headHeight;
    line-height: $headHeight;
    background-color: #fff;
    display: flex;
    align-items: center;
    padding: 0 30px;
    justify-content: space-between;

    .htips {
        font-size: 14px;
    }
}

.user-box {
    display: flex;
    align-items: center;

    .user {
        font-size: 12px;
        margin-right: 15px;
    }
}</style>
