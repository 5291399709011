import { createRouter, createWebHistory } from 'vue-router'
import Layout from '../layout/index.vue'
import ErrorPage from '../views/404.vue'

const routes = [
  {
    path: '/',
    component: Layout,
    meta: { title: 'Home' },
    redirect: "/batch",
    children: [
      {
        path: "/batch",
        name: "batch",
        component: () => import("@/views/batch-publish/index.vue"),
        meta: {
          title: "批量铺货",
        }
      },{
        path: "/record",
        name: "record",
        component: () => import("@/views/publish-record/index.vue"),
        meta: {
          title: "铺货记录",
        }
      },{
        path: "/batchEdit",
        name: "batchEdit",
        component: () => import("@/views/batch-edit/index.vue"),
        meta: {
          title: "批量修改",
        }
      },{
        path: "/editRecord",
        name: "editRecord",
        component: () => import("@/views/edit-record/index.vue"),
        meta: {
          title: "修改记录",
        }
      },{
        path: "/set",
        name: "set",
        component: () => import("@/views/publish-set/index.vue"),
        meta: {
          title: "铺货设置",
        }
      },{
        path: "/store",
        name: "store",
        component: () => import("@/views/my-store/index.vue"),
        meta: {
          title: "我的店铺",
        }
      }
    ]
  },
  {
    path: '/authsuccess',
    component: () => import("@/views/h5/auth-success.vue")
  },
  { path: '/:path(.*)', component: ErrorPage }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// 路由全局前置守卫
router.beforeEach((to, from, next) => {
  // 
  if(to.path === '/authSuccess'){
    const meta = document.createElement('meta')
    meta.content="user-scalable=0"
    meta.name="viewport"
    document.getElementsByTagName('head')[0].appendChild(meta)
  }
  next();
});

// 路由全局后置守卫
// router.afterEach((to, from, next) => {
//   console.log('路由全局后置守卫', to, from);
//   next();
// });

export default router
