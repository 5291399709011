export default function setWindowPublic() {
    window.public = {
        // 判断是否是在flutter环境 该判断只能判断生意网
        isFlutter() {
            var u = navigator.userAgent;
            if (u.indexOf('e3e3') > -1) {
                return true;
            } else {
                return false;
            }
        },
        // 判断系统
        isIOS() {
            var u = navigator.userAgent;
            if (u.indexOf('Android') > -1 || u.indexOf('Adr') > -1) {
                //android终端
                return false;
            } else if (!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
                return true;
            }
        },

        // iso通信方法
        setupWebViewJavascriptBridge(callback) {
            if (window.WebViewJavascriptBridge) {
                return callback(WebViewJavascriptBridge);
            }
            if (window.WVJBCallbacks) {
                return window.WVJBCallbacks.push(callback);
            }
            window.WVJBCallbacks = [callback];
            var WVJBIframe = document.createElement('iframe');
            WVJBIframe.style.display = 'none';
            WVJBIframe.src = 'wvjbscheme://__BRIDGE_LOADED__';
            document.documentElement.appendChild(WVJBIframe);
            setTimeout(function() {
                document.documentElement.removeChild(WVJBIframe)
            }, 0)
        },

        //验证该方法是否在flutter里声明
        judgmentFlutter(name, callback, noneCallback) {
            console.log(name, window[name])
            if (window[name] && callback) callback()
            else noneCallback && noneCallback()
        },

        //控制标题栏
        //type => 1/显示  0/隐藏
        showHideTitle(type, noneCallback) {
            // if (this.isFlutter()) {
            this.judgmentFlutter('showHideTitle', function() {
                window.showHideTitle.postMessage(type)
            }, noneCallback)
            // } else {
            /* if (this.isIOS()) {
                this.setupWebViewJavascriptBridge(function (bridge) {
                    bridge.callHandler("showHideTitle", type, function (response) { })
                })
            } else {
                window.go2 &&  window.go2.showHideTitle(type);
            } */
            // }

        },

        closeWindow() {
            // 现在没有原生的app，取消该判断
            // if (this.isFlutter()) {
            this.judgmentFlutter('closeWindow', function() {
                window.closeWindow.postMessage('')
            })
            // } else {
            //     if (this.isIOS()) {
            //         this.setupWebViewJavascriptBridge(function (bridge) {
            //             bridge.callHandler("closeWindow", '', function (response) { })
            //         })
            //     } else {
            //         window.go2 && window.go2.closeWindow();
            //     }
            // }

        },

    }
}