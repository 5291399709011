<template>
  <div class="app-wrapper">
    <SideBar />
    <div class="main-container">
      <layout-header />
      <AppMain />
      <div class="customer f14">
        <div
          class="item phone-item"
          @mouseover="showPhoneHandle"
          @mouseout="showPhone = false"
        >
          <span class="img-small phone-kf">
            <img src="@/assets/imgs/icons/icon-service.png" alt="" />
          </span>
          电话客服
          <div class="phone-number" v-show="showPhone">
            <img src="@/assets/imgs/icons/icon_phone.png" style="margin-right: 4px;" alt="">
            400-6611-603
          </div>
        </div>
        <div
          class="item wx-item"
          @mouseover="showQRCodeHandle"
          @mouseout="showQRcode = false"
        >
          <span class="img-small qr-code">
            <img src="@/assets/imgs/icons/icon_code.png" alt="" />
          </span>
          微信客服
          <div class="qr-code-img" v-show="showQRcode">
            <img src="@/assets/imgs/icons/wx-cus.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import SideBar from './components/sidebar.vue'
import LayoutHeader from './components/layout-header.vue'
import AppMain from './components/app-main.vue'
import { ref, unref } from 'vue'
import { ClickOutside as vClickOutside } from 'element-plus'
const buttonRef = ref()
const popoverRef = ref()
const onClickOutside = () => {
  unref(popoverRef).popperRef?.delayHide?.()
}
const showQRcode = ref(false)
function showQRCodeHandle() {
  showPhone.value = false
  showQRcode.value = true
}
const showPhone = ref(false)
function showPhoneHandle() {
  showPhone.value = true
  showQRcode.value = false
}

// document.addEventListener('click', () => {
//   console.log('点击了')
//   showQRcode.value = false
//   showPhone.value = false
// })
</script>

<style lang="scss" scoped>
.app-wrapper {
  @include clearfix;
  background-color: $mainContentBg;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  .main-container {
    height: 100%;
    margin-left: $sideBarWidth;
  }
  .customer {
    .item {
      display: flex;
      align-items: center;
      position: relative;
      height: 48px;
      line-height: 48px;
      position: absolute;
      bottom: 100px;
      right: -69px;
      z-index: 9999;
      background-color: $baseColor;
      color: #fff;
      padding: 0 8px 0 3px;
      border-radius: 5px 0 0 5px;
      &:hover {
        right: 0;
        transition: 0.5s;
      }
    }
    .phone-item {
      bottom: 160px;
      background-color: #ea781b;
    }
    .img-small {
      display: inline-block;
      width: 42px;
      height: 42px;
      border-radius: 4px;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .phone-kf {
      // background-color: #fff;
    }

    .common-wei {
      content: '';
      display: block;
      border-left: 10px solid #ea781b;
      border-right: 10px solid transparent;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      position: absolute;
      right: -20px;
      bottom: 10px;
    }
    .phone-number {
      position: absolute;
      left: -176px;
      bottom: 0;
      border-radius: 8px;
      width: 150px;
      height: 48px;
      color: #000;
      background-color: #fff;
      border: 1px solid #ea781b;
      display: flex;
      align-items: center;
      justify-content: center;
      &::before {
        @extend .common-wei;
        border-left: 10px solid #fff;
        right: -19px;
        bottom: 10px;
        z-index: 1;
      }
      &::after {
        @extend .common-wei;
        border-left: 10px solid #ea781b;
        right: -20px;
        bottom: 10px;
      }
    }
    .qr-code-img {
      position: absolute;
      left: -176px;
      bottom: 0;
      border-radius: 8px;
      width: 160px;
      height: 160px;
      background-color: #fff;
      border: 1px solid $baseColor;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 136px;
        height: 136px;
      }
      &::before {
        @extend .common-wei;
        border-left: 10px solid #fff;
        right: -19px;
        bottom: 10px;
        z-index: 1;
      }
      &::after {
        @extend .common-wei;
        border-left: 10px solid $baseColor;
        right: -20px;
        bottom: 10px;
      }
    }
  }
}
</style>
<style>
::v-deep(.qr-code-popover
    .el-popper[data-popper-placement^='left']
    > .el-popper__arrow::before) {
  border: 1px solid #f00 !important;
  border-left-color: #f00 !important;
  border-bottom-color: #f00 !important;
}
</style>
